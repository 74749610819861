.new-background-color{
    --background: #515561;
}

ion-tab-button.tab-selected {
    --background: var(--ion-color-dark) !important;
    --color-selected: var(--ion-color-light);
  }
.ion-item-border-color{
    --border-color:#552f3e;
    --border-width:5px;
}
.alert-button{
  --color: #164f6c;
}

  .tabbuttonclass{

    --color: #164f6c;
    
    --color-selected: #164f6c;
    
    }

  .tabbuttonclass:hover {

    --color: #164f6c;
    
    --color-selected: #5d58e0;
    
}

.altbtncancel{

  color: #3880ff !important;
  
  --color-selected: #5d58e0;
  
}

.altbtnyes{

  color: #3880ff !important;
  
  --color-selected: #5d58e0;
  
}

.customAlertCss .alert-button {
      color: #3880ff !important;
}

.customAlertCss [aria-checked=true].sc-ion-alert-md .alert-radio-icon {
  border-color: #3880ff !important;
  background-color: #3880ff !important;
}
.in-item{
  max-width:92% !important;
  --padding-start:0px !important;
}
.scrollable-element {
  scrollbar-color: red yellow; /* red is for the thumb and yellow is for the track */
}

ion-item{
  --padding-start:1px !important;
}
ion-grid{
  --inner-padding-end:1px !important;
}
.transform ion-button{
  height: 1.8em !important;
  --padding-start:0.2em !important;
  --padding-end : 0.2em !important;
  font-size :8px !important;
}
:host{
  --inner-padding-end:1px !important;
}
.ion-text-wrap{
  line-height: 3.2 !important;
}
.right-botton{
  margin-top: 7px !important;
}

table {
  width: 90%;
  border-spacing: 0;
  border: 1px solid black;
}
tr:last-child td {
  border-bottom: 0;
}
td{
  height: 50px;
}  

th,td {
    margin: 0;
    padding: 22px;
    height: 10px;
    column-span: 10px;
    border-bottom: 1px solid rgb(143, 78, 78);
    border-right: 1px solid rgb(143, 78, 78);
    
}
  th{
    text-align: left;
  }

td:last-child {
  border-right: 0;
}
